<template>
  <div class="view view--protocole--add" v-if="!isLoading">
    <div class="view-header view-header--protocol-grouping">
      <div class="view-header__footer">
        <BtnBack :to="{ 'name': 'protocolEssaisGroupings' }" />
        <h1 class="page-title">{{ pageTitle }}&nbsp;: {{ grouping.nom }}</h1>
      </div>
    </div>

    <div class="view-body">
      <Section>
        <Container>
          <div class="title-bar margin-bottom">
            <h2>{{ pageTitle }} <span>(0&nbsp;essais et 0&nbsp;variables)</span></h2>
          </div>
          <KeyValue v-if="grouping" label="Nom du regroupement" :value="grouping.nom" />
          <KeyValue v-if="grouping" label="Type d'analyse"
            :value="grouping.type === 1 ? 'Rapport sur données brutes' : 'Rapport sur données moyennées'" />
        </Container>
      </Section>

      <Section v-if="grouping?.etat?.uid === 'ERROR'">
        <Container>
          <MiniTag v-if="grouping?.etat?.uid === 'ERROR'" :text="grouping?.etat?.designation" color="error" />
          {{ grouping?.details_erreur }}
        </Container>
      </Section>
      <Container>
        <List :listData="trials" :header="[{ label: 'Nom de l\'essai' },
        { label: 'Année' },
        { label: 'Culture' },
        { label: 'Entité' },
        { label: 'Modalités' },
        { label: 'Variables' }]" :items="['nom', 'annee', 'cultures', 'entite', 'modalites', 'variables']"
          disableCheckbox disableActions>
          <template v-slot:cultures="{ item }">
            <template v-if="item?.cultures.length > 0">
              <div class="tags">
                <MiniTag v-for="culture in item?.cultures" :key="culture.id" :id="culture.uid" :text="culture.nom" />
              </div>
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

          <template v-slot:annee="{ item }">
            <template v-if="item?.annee">
              {{ item?.annee?.valeur }}
            </template>
            <template v-else>
              <span></span>
            </template>
          </template>

        </List>
      </Container>

    </div>

  </div>

  <NavigationDropdownProtocol />

  <Loader :active="isLoading" />

</template>
<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import BtnBack from '@/components/base/BtnBack.vue'
import NavigationDropdownProtocol
  from '@/views/componentsViews/navigationDropdown/NavigationDropdownProtocol.vue'
import MiniTag from '@/components/base/MiniTag.vue'
import KeyValue from '@/components/form/KeyValue.vue'
import Loader from '@/components/layout/Loader.vue'
import List from '@/components/list/List.vue'

export default {
  name: 'ProtocolGroupingAddEditView',

  components: {
    List,
    BtnBack,
    Section,
    Container,
    NavigationDropdownProtocol,
    KeyValue,
    MiniTag,
    Loader,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      isLoading: true,
      grouping: null,
      trials: [],
      routeParams: {
        id: this.$route.params.id,
      },
      listData: [],
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    async loadData() {
      this.isLoading = true
      const groupingResponse = await this.fetchService.get(`protocole/${this.$route.params.id}/regroupement_essai/${this.$route.params.rid}`)
      this.grouping = groupingResponse.data

      // eslint-disable-next-line
      for (const element of this.grouping.essais) {

        // eslint-disable-next-line
        const essaiResponse = await this.fetchService.get(`essai/${element.id}`)
        console.log('essaiResponse', essaiResponse)

        const modalites = this.grouping.modalites.filter((item) => item.essais[element.id].selected === true)
        const variables = this.grouping.variables.filter((item) => item.essais[element.id].selected === true)

        const itemToAdd = {
          nom: essaiResponse?.data.nom,
          cultures: essaiResponse?.data?.protocole?.cultures,
          annee: essaiResponse?.data?.protocole?.annee_recolte,
          entite: essaiResponse?.data?.protocole?.entite?.nom,
          modalites: modalites.length,
          variables: variables.length,
        }

        this.trials.push(itemToAdd)
      }

      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  >span {
    color: $color-gray;
    font-weight: $font-weight-normal;
  }
}

h3 {
  margin-bottom: $gutter;
}

.variable {
  font-weight: $font-weight-semibold;
}

.capped-width {
  max-width: 60rem;
}
</style>
